const initialState = {
  language: '',
};

/* eslint-disable import/no-anonymous-default-export */
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANG':
      return { ...state, language: action.payload.language }

    default:
      return state;
  }
}