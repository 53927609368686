import React, { useEffect, useState } from "react";
import axios from "axios";
import OperatorEventSubList from "./OperatorEventSubList";
import {
    changeTimezone,
    getDateWithoutTimezone,
    hideScanner,
} from "../../common/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { IsEmpty } from "../../common/helpers";
import { useLocation } from "react-router-dom";
import OperatorEventRereg from "./OperatorEventRereg";

function OperatorEventList() {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(0);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        // simpan tempat operator bertugas bila ada
        const _churchId = query.get("church_id");
        if (_churchId) {
            localStorage.setItem("operator_church_id", _churchId);
        }
        let urlEvent = `${process.env.REACT_APP_API_URL}events?is_operator=1`;
        axios({
            method: "GET",
            url: urlEvent,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem(
                    "operator_token"
                )}`,
            },
        })
            .then((res) => {
                if (res.data.success) {
                    // const _events = res.data.data.filter(
                    //     (o) => o.name === "GMS Christmas Celebration 2022"
                    // );
                    setEvents(res.data.data);
                }
            })
            .catch((err) => {
                err.response.status === 500 || err.response.status !== 404
                    ? setMessage(
                          "There's an error while fetching regular events"
                      )
                    : setMessage("Tidak ada Event yang sedang berlangsung");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [query]);

    if (!isLoading && !IsEmpty(events)) {
        events.forEach((part, index) => {
            part.uniqueDates = [];
            part.sub_event.forEach((_part, _index) => {
                _part.start_date = changeTimezone(_part.start_date);
                _part.end_date = changeTimezone(_part.end_date);
                _part.service_date = getDateWithoutTimezone(_part.start_date);
                if (
                    part.uniqueDates
                        .map((e) => {
                            return e.date;
                        })
                        .indexOf(_part.service_date) === -1
                ) {
                    let uniqueDate = [];
                    uniqueDate.date = _part.service_date;
                    part.uniqueDates.push(uniqueDate);
                }
            });

            part.uniqueDates.forEach((_part, _index) => {
                _part.uniqueRooms = [];
                part.sub_event.forEach((__part, __index) => {
                    if (
                        _part.date === __part.service_date &&
                        _part.uniqueRooms.indexOf(__part.room) === -1
                    ) {
                        _part.uniqueRooms.push(__part.room);
                    }
                });
            });
        });
    }

    // load data scanner
    hideScanner();
    return (
        <>
            {!isLoading && !IsEmpty(events) ? (
                <>
                    <div className="container-fluid no-padding">
                        <div style={headerStyle} className="text-center mt-3">
                            GMS Event Operator
                        </div>

                        <div
                            className="row"
                            style={{ justifyContent: "center" }}
                        >
                            <div className="col-sm-8 col-12">
                                {/* Select Sub-event */}
                                <div className="d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        style={{ marginRight: "10px" }}
                                    />
                                    <select
                                        value={selectedEvent}
                                        onChange={(e) =>
                                            setSelectedEvent(e.target.value)
                                        }
                                        style={selectServiceStyle}
                                    >
                                        {events.map((event, index) => (
                                            <option key={index} value={index}>
                                                {event.exclusive !== null
                                                    ? `[${event.exclusive.toUpperCase()}] `
                                                    : null}
                                                {event.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Sub-event Item List */}
                                <div style={eventListStyle}>
                                    {events[selectedEvent].ticket_type ===
                                        null && (
                                        <OperatorEventSubList
                                            event={events[selectedEvent]}
                                        />
                                    )}
                                    {events[selectedEvent].ticket_type ===
                                        "rereg" && (
                                        <OperatorEventRereg
                                            event={events[selectedEvent]}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : !isLoading && !IsEmpty(message) ? (
                //* Show message if event empty>
                <div
                    className="mt-4"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <h6>{message}</h6>
                </div>
            ) : (
                //* Show Spinner while loading
                <div
                    className="mt-4"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner animation="border" />
                </div>
            )}
        </>
    );
}

const headerStyle = {
    color: "#333",
    marginBottom: "30px",
    textTransform: "uppercase",
    fontWeight: "bold",
};

const selectServiceStyle = {
    width: "85%",
    border: "none",
    borderBottom: "solid 1px #aaa",
    backgroundColor: "transparent",
};

const eventListStyle = {
    marginLeft: "3%",
    marginRight: "3%",
    marginTop: "30px",
};

export default OperatorEventList;
