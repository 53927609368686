import React, { Component } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { IsEmpty } from "../../common/helpers";
import { Redirect } from "react-router";

var churchId;

class AuthLoginOperator extends Component {
    state = {
        loginStatus: false,
        loginMessage: "",
    };

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const code = params.get("auth_code")
            ? params.get("auth_code")
            : params.get("code");
        const exclusive = params.get("exclusive");

        if (!IsEmpty(code)) {
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}login`,
                data: {
                    auth_code: code,
                },
                headers: { Accept: "application/json" },
            })
                .then((res) => {
                    if (
                        res.data.success &&
                        res.data.data.roles.includes("Operator")
                    ) {
                        localStorage.setItem(
                            "operator_token",
                            res.data.data.token
                        );
                        churchId = res.data.data.church_info.id;
                        localStorage.setItem(
                            "church_name",
                            res.data.data.church_info.name
                        );
                        localStorage.setItem("exclusive", exclusive);
                        this.setState({ loginStatus: true });
                    } else {
                        this.setState({ loginStatus: "Login Operator Failed" });
                    }
                })
                .catch((err) => {
                    err.response.status === 500
                        ? this.setState({
                              loginMessage: `There was a problem with the server\n${err.response.status}`,
                          })
                        : this.setState({
                              loginMessage: `There was a problem with the network\n${err.response.status}`,
                          });
                });
        } else {
            this.setState({ loginMessage: "Login Failed" });
        }
    }
    handleRedirect() {
        // GMS Surabaya Barat
        if (churchId === "8f4c6a26b91c743ac3168a8f7c022e2b") {
            return <Redirect to={{ pathname: "/operator/select-church" }} />;
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/operator/events",
                        search: `?church_id=${churchId}`,
                    }}
                />
            );
        }
    }

    render() {
        return (
            <div className="container mt-4 text-center">
                {this.state.loginStatus === false &&
                IsEmpty(this.state.loginMessage) ? (
                    <Spinner animation="border" />
                ) : (
                    <span>{this.state.loginMessage}</span>
                )}
                {this.state.loginStatus === true && this.handleRedirect()}
            </div>
        );
    }
}

export default AuthLoginOperator;
