// Church ID
export const churchId = {
  Balikpapan: "8f4c6a26b91c743ac3168a8f7c010ab3",
  Banjarmasin: "8f4c6a26b91c743ac3168a8f7c0185f0",
  JakartaBarat: "8f4c6a26b91c743ac3168a8f7c00fd99",
  Jember: "8f4c6a26b91c743ac3168a8f7c016176",
  Jombang: "8f4c6a26b91c743ac3168a8f7c011239",
  Madiun: "8f4c6a26b91c743ac3168a8f7c019dae",
  Malang: "8f4c6a26b91c743ac3168a8f7c00f1bb",
  Mataram: "8f4c6a26b91c743ac3168a8f7c0279c8",
  Medan: "8f4c6a26b91c743ac3168a8f7c013425",
  PalangkaRaya: "1282e1755dbfddaac3cab552fa01a272",
  Palembang: "8f4c6a26b91c743ac3168a8f7c01155d",
  Semarang: "8f4c6a26b91c743ac3168a8f7c011271",
  Sidoarjo: "8f4c6a26b91c743ac3168a8f7c00ee89",
  Singapore: "8f4c6a26b91c743ac3168a8f7c028d55",
  SurabayaBarat: "8f4c6a26b91c743ac3168a8f7c022e2b",
  Sydney: "1282e1755dbfddaac3cab552fa014c77",
  Tangerang: "421145765f0055368d0b319e7d0382a8",
  Tulungagung: "8f4c6a26b91c743ac3168a8f7c011cd7",
}

// l10n
export const l10n = {
  acknowledge: {
    en: "I acknowledged and agreed to follow all safety measure processes.",
    ind: "Saya menyetujui syarat dan ketentuan di atas",
  },
  agree: {
    en: "Agree",
    ind: "Setuju",
  },
  cancel: {
    en: "Cancel",
    ind: "Batal",
  },
  cancelSelectedTicket: {
    en: "Cancel this ticket?",
    ind: "Batalkan Tiket Ini?",
  },
  cancelTicket: {
    en: "Cancel Ticket",
    ind: "Batalkan",
  },
  cancelTicketForOrder: {
    en: "Cancel last service ticket to make this order?",
    ind: "Batalkan Tiket Ibadah sebelumnya untuk membuat pesanan ini?",
  },
  confirmation: {
    en: "Confirmation",
    ind: "Konfirmasi",
  },
  date: {
    en: "Date",
    ind: "Tanggal",
  },
  myTicket: {
    en: "My Ticket",
    ind: "Tiket Saya",
  },
  needAgreement: {
    en: "Please agree to the terms and conditions above",
    ind: "Harap menyetujui syarat dan ketentuan di atas",
  },
  next: {
    en: "Next",
    ind: "Berikutnya",
  },
  numbersOfTicket: {
    en: "Numbers of Ticket",
    ind: "Jumlah Tiket",
  },
  order: {
    en: "Order",
    ind: "Pesan",
  },
  overflowAgree: {
    en: "I am willing to be placed in the overflow room if the main hall is full ",
    ind: "Saya bersedia ditempatkan di ruang overflow jika main hall sudah penuh",
  },
  quota: {
    en: "Quota",
    ind: "Kuota",
  },
  processTicket: {
    en: "Process Ticket?",
    ind: "Proses Tiket?",
  },
  room: {
    en: "Room",
    ind: "Ruangan",
  },
  seatsLeft: {
    en: "seats left",
    ind: "kursi tersisa",
  },
  selectTicket: {
    en: "Please choose number of tickets that want to be ordered",
    ind: "Harap pilih jumlah tiket yang ingin dipesan",
  },
  session: {
    en: "Session",
    ind: "Sesi",
  },
  ticket: {
    en: "Ticket",
    ind: "Tiket",
  },
  time: {
    en: "Time",
    ind: "Waktu",
  },
  verifyAccount: {
    en: "Please verify Email and phone in your ROSC Account",
    ind: "Harap melakukan verifikasi Email dan No HP pada GMS Akun Anda",
  },
}

export const disclaimer = {
  Singapore: {
    welcome: `
        <p>Welcome to Rose of Sharon Church (ROSC) Ticketing Service,</p>
        <p>Please take a minute to understand ROSC ticketing system:</p>
        <ol>
          <li>For single, you are allowed to book 1 ticket only.<br />
            For family, 1 account would be able to book up to 2 tickets.<br />
            <i><small>Note: if you have 4 members in your household, please book separately.</small></i>
          </li>
          <li>You are not allowed to re-arrange / choose the seat.<br />
            <i><small>Note: for family, there will be limited couple seats available.</small></i>
          </li>
          <li>You will be given QR code as a ticket upon completion of registration.<br />
            <i><small>Note: no entrance to hall without QR code / Ticket.</small></i>
          </li>
          <li>Ticket only can be scanned 1 time and non-transferable.<br />
            For family, If you booked for 2 persons, 1 QR code will allow 2 person to enter.<br />
            <i><small>Note: you have to enter the hall together and do not screenshot your ticket.</small></i>
          </li>
          <li>Please be on time and your ticket will be expired after 10 minutes of the service.<br />
            <i><small>Note: come 15 mins before your service time to go through all arrival processes.</small></i>
          </li>
        </ol>`,

    body: `
        <p>Please read through the statements carefully.</p>
        <ol>
          <li>I HAVE NOT come into contact with any person(s) suspected / diagnosed with COVID in the past 14 days.</li>
          <li>I HAVE NOT been issued a Leave of Absence (LOA).</li>
          <li>NEITHER I NOR my household member travelled overseas in the last 14 days.</li>
          <li>NEITHER I NOR my household member have been issued a Home Quarantine Order (HQO) or Stay-Home Notice (SHN).</li>
          <li>I HAVE to follow all safety measure processes.<br />
            a. Check-in/out via SafeEntry<br />
            b. Temperature check (below 37.5ºC)<br />
            c. Wearing mask at all times<br />
            d. No mingling / cross between zone
          </li>
        </ol>`,
  },

  Sydney: {
    welcome: `
        <p>Welcome to GMS Church Services Ticketing,</p>
        <p>with regards to Government regulations, we will accommodate Church service attendees by using an online booking system.</p>
        <p>Here are the terms and conditions :
        </p>
        <ol>
          <li>Applicable account can only register for 1 service per-Sunday.</li>
          <li>Enter the mainhall by using a QR code ticket in GMS App (screenshot is not permitted).</li>
          <li>The QR code can only be used once. If there are multiple people registered in the QR code, please enter the mainhall together with the people registered.</li>
          <li>The QR code is only good for 15-minutes after the services begin.</li>
          <li>The QR code cannot be transferred to/presented by someone else.</li>
          <li>1 account can only register max 2 people for 1 service per-week.</li>
        </ol>`,

    body: `
        <ol>
          <li>Attendees are required to wear a <b>3-ply mask</b> (surgical/non).</li>
          <li>Attendees present must have a healthy physical condition.</li>
          <li>Attendees must never be directly exposed to other people with Covid-19.</li>
          <li>Body temperature check will be conducted upon entry. Upon failing to meet these requirements, please attend the service online.</li>
        </ol>`,
  },

  Jabodetabek: {
    welcome: `
        <p>Welcome to GMS Church Services Ticketing,</p>
        <p>Mengingat ada pembatasan kuota ibadah sesuai dengan ketentuan pemerintah, maka semua kursi di hari H (Mainhall dan Overflow) akan menggunakan sistem Ticketing.</p>
        <p>Berikut ini adalah syarat dan ketentuannya :
        </p>
        <ul>
          <li>Anda tidak pernah berkontak langsung dengan pasien pengidap COVID-19 dalam 14 hari terakhir.</li>
          <li>Anda tidak sedang sakit demam, batuk dan sakit tenggorokan dalam 3 hari terakhir.</li>
          <li>Anda memiliki kondisi fisik yang sehat, dan tidak memiliki penyakit bawaan seperti diabetes, jantung, dll.</li>
          <li>Anda tidak dalam anjuran Karantina Mandiri.</li>
          <li>Akan dilakukan konfirmasi pengecekan suhu tubuh. Bila tidak memenuhi ketentuan, jemaat diharapkan untuk mengikuti ibadah secara online.</li>
          <li>Pengecekan suhu tubuh (maksimal 37.5 C).</li>
          <li>Anda wajib menggunakan masker medis minimal 3Ply (tidak diperbolehkan memakai masker kain) dan tidak dibuka selama berada di area gereja.</li>
          <li>Anda wajib mematuhi peraturan yang telah ditetapkan dan bersedia untuk tidak mengikuti ibadah onsite, jika terjadi pelanggaran dari peraturan yang ada.</li>
          <li>1 akun berlaku untuk 1 orang dan QR Code tidak dapat dipindahtangankan.</li>
          <li>Masuk Mainhall menggunakan Ticket berupa QR Code di Aplikasi (tidak boleh menggunakan Screenshot).</li>
          <li>QR Code hanya berlaku hingga 15 menit setelah ibadah dimulai. Lebih dari 15 menit tiket akan hangus.</li>
        </ul>`,

    body: `
        <ol>
          <li>Saya tidak pernah berkontak langsung dengan pasien pengidap COVID-19 dalam 14 hari terakhir.</li>
          <li>Saya tidak sedang sakit demam, batuk dan sakit tenggorokan dalam 3 hari terakhir.</li>
          <li>Saya memiliki kondisi fisik yang sehat, dan tidak memiliki penyakit bawaan seperti diabetes, jantung, dll.</li>
          <li>Saya tidak dalam anjuran Karantina Mandiri.</li>
        </ol>`,
  },

  SurabayaBarat: {
    welcome: `
        <p>Welcome to GMS Church Services Ticketing,</p>
        <p>Mengingat ada pembatasan kuota ibadah sesuai dengan ketentuan pemerintah, maka semua kursi di Mainhall akan menggunakan sistem Ticketing.</p>
        <p>Berikut ini adalah syarat dan ketentuannya :
        </p>
        <ol>
          <li>1 akun hanya bisa mendaftar untuk 1 ibadah saja.</li>
          <li>Anak-anak yang membutuhkan kursi, harus memiliki tiket.</li>
          <li>Masuk Mainhall menggunakan Ticket berupa QR Code di Aplikasi (tidak boleh menggunakan Screenshot dan tidak dapat dipindahtangankan/dititipkan)</li>
          <li>QR Code hanya bisa digunakan untuk 1x scan. Jika dalam 1 QR code berlaku untuk lebih dari 1 tiket, diharap memasuki ruang ibadah bersamaan sesuai dengan jumlah tiket yang dipesan.</li>
          <li>QR Code hanya berlaku hingga 15 menit setelah ibadah dimulai. Apabila tiket expired, maka wajib untuk memesan ulang tiket.</li>
          <li>Jemaat wajib menggunakan masker dan tidak dibuka selama berada di area gereja.</li>
          <li>Bersedia mematuhi seluruh protokol dan peraturan yang ditetapkan dan bersedia untuk meninggalkan tempat ibadah jika melanggar protokol dan peraturan.</li>
        </ol>`,

    body: `
        <ol>
          <li>Jemaat wajib menggunakan <b>Masker 3Ply/KN95/KF94/Duckbill</b>. Masker kain, scuba, dan masker lain tidak diperbolehkan. Face Shield tidak wajib.</li>
          <li>Jemaat yang mengikuti GMS Church Services harus memiliki kondisi fisik yang sehat, tidak dalam kondisi komorbid (penyakit bawaan spt: diabetes, jantung).</li>
          <li>Jemaat tidak sakit dalam 7 hari terakhir.</li>
          <li>Anggota keluarga serumah tidak sakit dalam 7 hari terakhir.</li>
          <li>Tidak pernah melakukan kontak dengan penderita Covid-19 dalam 30 hari terakhir.</li>
          <li>Akan dilakukan konfirmasi usia jemaat dan pengecekan suhu tubuh. Bila tidak memenuhi ketentuan, jemaat diharapkan untuk mengikuti ibadah secara online.</li>
        </ol>`,
  },

    Sidoarjo: {
        welcome: `
        <p>Welcome to GMS Church Services Ticketing,</p>
        <p>Mengingat ada pembatasan kuota ibadah sesuai dengan ketentuan pemerintah, maka semua kursi di hari H (Mainhall dan Overflow) akan menggunakan sistem Ticketing.</p>
        <p>Berikut ini adalah syarat dan ketentuannya :
        </p>
        <ol>
          <li>1 akun berlaku hanya bisa mendaftar untuk 1 ibadah saja.</li>
          <li>Masuk Mainhall menggunakan Ticket berupa QR Code di Aplikasi (tidak boleh menggunakan Screenshot).</li>
          <li>QR Code hanya bisa digunakan untuk 1x scan. Jika dalam 1 QR code berlaku untuk lebih dari 1 tiket, diharap memasuki ruang ibadah bersamaan sesuai dengan jumlah tiket yang dipesan.</li>
          <li>QR Code hanya berlaku hingga 15 menit setelah ibadah dimulai.</li>
          <li>QR Code tidak dapat dipindahtangankan/dititipkan.</li>
          <li>Jemaat wajib menggunakan masker dan tidak dibuka selama berada di area gereja.</li>
          <li>Bersedia mematuhi seluruh protokol dan peraturan yang ditetapkan dan bersedia untuk meninggalkan tempat ibadah jika melanggar protokol dan peraturan.</li>
        </ol>`,

    body: `
        <ol>
          <li>GMS Church Services diperuntukkan untuk jemaat berusia 7 tahun ke atas.</li>
          <li>Jemaat wajib menggunakan <b>Masker 3Ply</b> (medis/nonmedis), Face Shield tidak wajib.</li>
          <li>Jemaat yang mengikuti GMS Church Services harus memiliki kondisi fisik yang sehat, tidak dalam kondisi komorbid (penyakit bawaan spt: diabetes, jantung).</li>
          <li>Jemaat tidak sakit dalam 3 hari terakhir.</li>
          <li>Anggota keluarga serumah tidak sakit dalam 3 hari terakhir.</li>
          <li>Tidak pernah melakukan kontak dengan penderita Covid-19.</li>
          <li>Akan dilakukan konfirmasi usia jemaat dan pengecekan suhu tubuh. Bila tidak memenuhi ketentuan, jemaat diharapkan untuk mengikuti ibadah secara online.</li>
        </ol>`,
    },

    National: {
        welcome: `
        <p>Welcome to GMS Church Services Ticketing,</p>
        <p>Mengingat adanya pembatasan kuota ibadah sesuai dengan ketentuan pemerintah, maka semua kursi akan menggunakan sistem Ticketing.</p>
        <p>Syarat dan ketentuan:
        </p>
        <ol>
          <li>1 akun hanya bisa mendaftar untuk 1 ibadah saja.</li>
          <li>Anak-anak yang membutuhkan kursi, harus memiliki tiket.</li>
          <li>Memasuki Mainhall maupun ruangan Overflow wajib menunjukkan Ticket berupa QR Code di Aplikasi (tidak boleh menggunakan Screenshot dan tidak dapat dipindahtangankan/dititipkan)</li>
          <li>Karena first come first served, jemaat tidak bisa memilih ruangan Mainhall maupun Overflow.</li>
          <li>QR Code hanya bisa digunakan untuk 1x scan. Jika dalam 1 QR code berlaku untuk lebih dari 1 tiket, diharap memasuki ruang ibadah bersamaan sesuai dengan jumlah tiket yang dipesan.</li>
          <li>QR Code hanya berlaku hingga 15 menit setelah ibadah dimulai. Apabila tiket expired, maka wajib untuk memesan ulang tiket.</li>
          <li>Jemaat wajib menggunakan masker dan tidak dibuka selama berada di area gereja.</li>
          <li>Bersedia mematuhi seluruh protokol dan peraturan yang ditetapkan dan bersedia untuk meninggalkan tempat ibadah jika melanggar protokol dan peraturan.</li>
        </ol>`,

        body: `
        <ol>
          <li>Jemaat wajib menggunakan <b>Masker KN-95 atau KF-94</b>. Masker kain, scuba, dan masker lain tidak diperbolehkan. Face Shield tidak wajib.</li>
          <li>Jemaat yang mengikuti GMS Church Services harus memiliki kondisi fisik yang sehat, tidak dalam kondisi komorbid (penyakit bawaan seperti: diabetes, jantung, dll).</li>
          <li>Jemaat tidak sakit dalam 7 hari terakhir.</li>
          <li>Anggota keluarga serumah tidak sakit dalam 7 hari terakhir.</li>
          <li>Tidak pernah melakukan kontak dengan penderita Covid-19 dalam 30 hari terakhir.</li>
          <li>Akan dilakukan konfirmasi usia jemaat dan pengecekan suhu tubuh. Bila tidak memenuhi ketentuan, jemaat diharapkan untuk mengikuti ibadah secara online.</li>
        </ol>`,
    },
}
