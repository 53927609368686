import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./operator-select-church.css";

const churches = [
    {
        id: "8f4c6a26b91c743ac3168a8f7c022e2b",
        name: "GMS Surabaya Barat",
    },
    {
        id: "8f4c6a26b91c743ac3168a8f7c022f58",
        name: "GMS 2 Surabaya Selatan",
    },
    {
        id: "8f4c6a26b91c743ac3168a8f7c0235d3",
        name: "GMS 2 Surabaya Timur",
    },
];

function OperatorSelectChurch() {
    return (
        <div className="container-fluid">
            <div className="text-center mt-3" style={headerStyle}>
                GMS Event Operator
            </div>
            <Card style={cardStyle}>
                <Card.Header style={cardHeaderStyle}>
                    Pilih Tempat Bertugas
                </Card.Header>
                <Card.Body>
                    <div>
                        {churches.map((church) => {
                            return (
                                <div
                                    key={`select-${church.id}`}
                                    className="mb-4"
                                >
                                    <Link
                                        to={{
                                            pathname: "/operator/events",
                                            search: `?church_id=${church.id}`,
                                        }}
                                        className="btn btn-primary w-100"
                                        style={{
                                            paddingTop: 20,
                                            paddingBottom: 20,
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {church.name}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

const headerStyle = {
    color: "#333",
    marginBottom: "30px",
    textTransform: "uppercase",
    fontWeight: "bold",
};

const cardStyle = {
    marginBottom: "15px",
    backgroundColor: "#494d57",
    borderRadius: "10px",
};

const cardHeaderStyle = {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "8px",
    backgroundColor: "#1d2434",
    color: "white",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export default OperatorSelectChurch;
